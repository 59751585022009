import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  createOrganizationTeamStat,
  getOrganizationTeam,
  getUserProfile,
  updatePickleballStats,
  addPickleballStats
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import QuantityInput from "../AppInput/NumberInput"

function PickleballStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  playerStatOption,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_game: "",
    opponent_team: "",
    opponent_team_custom: "",
    serves: 0,
    aces: 0,
    double_faults: 0,
    unforced_errors: 0,
    forced_errors: 0,
    winning_shots: 0,
    net_points_won: 0,
    points_won_on_serve: 0,
    points_won_on_return: 0,
    "return": 0,
    loading: false,
    teams: [],
    userProfileData: null
  })
  const {
    date_of_game,
    opponent_team,
    opponent_team_custom,
    serves,
    aces,
    double_faults,
    unforced_errors,
    forced_errors,
    winning_shots,
    net_points_won,
    points_won_on_serve,
    points_won_on_return,
    loading,
    teams,
    userProfileData
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if (visible) {
      getData()
      _getProfileData()
    }
  }, [visible])

  const _getProfileData = async () => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("token")
      const res = await getUserProfile(user?.role === "Parent" ? playerId?.userID : user?.id, token)
      handleChange("userProfileData", res?.data)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_game", statData?.date_of_game)
      // getData(`?`)
      if (teams?.length && teams?.some((e) => e?.id == statData?.opponent_team)) {
        setDropDownText(teams?.find((e) => e?.id == statData?.opponent_team)?.team_name)
      }
      handleChange("opponent_team", statData?.opponent_team)
      handleChange("opponent_team_custom", statData?.opponent_team_custom)
      handleChange("serves", statData?.serves)
      handleChange("aces", statData?.aces)
      handleChange("double_faults", statData?.double_faults)
      handleChange("unforced_errors", statData?.unforced_errors)
      handleChange("forced_errors", statData?.forced_errors)
      handleChange("winning_shots", statData?.winning_shots)
      handleChange("net_points_won", statData?.net_points_won)
      handleChange("points_won_on_serve", statData?.points_won_on_serve)
      handleChange("points_won_on_return", statData?.points_won_on_return)
      handleChange("return", statData?.return)
    } else {
      handleReset()
    }
  }, [statData, visible, teams])

  const handleReset = () => {
    handleChange("date_of_game", "")
    handleChange("opponent_team", "")
    handleChange("serves", 0)
    handleChange("aces", 0)
    handleChange("double_faults", 0)
    handleChange("unforced_errors", 0)
    handleChange("forced_errors", 0)
    handleChange("winning_shots", 0)
    handleChange("net_points_won", 0)
    handleChange("points_won_on_serve", 0)
    handleChange("points_won_on_return", 0)
    handleChange("return", 0)
    setDropDownText("")
  }

  const getData = async (payload = '') => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(payload, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (opponent_team === "" && opponent_team_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const payload = {
          date_of_game,
          opponent_team,
          opponent_team_custom,
          serves,
          aces,
          double_faults,
          unforced_errors,
          forced_errors,
          winning_shots,
          net_points_won,
          points_won_on_serve,
          points_won_on_return,
          "return": state?.return
        }
        if (user?.role === "Parent") {
          payload.player_id = playerId?.id // Add player_id if user's role is parent
        }
        await addPickleballStats(payload, token)
        handleChange("loading", false)
        handleReset()
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (opponent_team === "" && opponent_team_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        if (organization) {
          const payload = {
            date_of_game,
            opponent_team,
            organization: organizationData?.id,
            team: teamID
          }
          await createOrganizationTeamStat(payload, token)
        } else {
          const payload = {
            date_of_game,
            opponent_team,
            opponent_team_custom,
            serves,
            aces,
            double_faults,
            unforced_errors,
            forced_errors,
            winning_shots,
            net_points_won,
            points_won_on_serve,
            points_won_on_return,
            "return": state?.return
          }
          if (user?.role === "Parent") {
            payload.player_id = playerId?.id // Add player_id if user's role is parent
          }
          await updatePickleballStats(statData?.id, payload, token)
        }
        handleChange("loading", false)
        handleReset()
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const positionName = userProfileData?.player?.first_position_play?.name
  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <Grid container spacing={3}>
          {user?.role == "Parent" && (
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  label={"Adding stats for"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={playerId?.name}
                  name={"playerId"}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <AppInput
              label={"Date of game"}
              max={moment().format("YYYY-MM-DD")}
              type={"date"}
              height={40}
              required={true}
              onChange={handleChange}
              value={date_of_game}
              name={"date_of_game"}
            />
            {dateOfGameError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text={"Team Played"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={
                opponent_team_custom === "" ? dropDownText : opponent_team_custom
              }
              onChange={(event, newValue) => {
                handleChange("opponent_team_custom", "")
                handleChange(
                  "opponent_team",
                  teams[event?.target?.dataset?.optionIndex]?.id
                )
                setDropDownText(newValue)
              }}
              inputValue={
                opponent_team_custom === "" ? dropDownText : opponent_team_custom
              }
              onInputChange={(event, newInputValue) => {
                handleChange("opponent_team_custom", newInputValue)
                handleChange("opponent_team", "")
              }}
              options={teams?.map(option => option.team_name)}
              // getOptionLabel={(option) => option.team_name}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                    // Add your desired z-index value here
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
            {teamPlayedError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Serves (S)"}
              onChange={handleChange}
              value={serves}
              name={"serves"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Aces (A)"}
              onChange={handleChange}
              value={aces}
              name={"aces"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Double Faults (DF)"}
              onChange={handleChange}
              value={double_faults}
              name={"double_faults"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Unforced Errors (UE)"}
              onChange={handleChange}
              value={unforced_errors}
              name={"unforced_errors"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Forced Errors (FE)"}
              onChange={handleChange}
              value={forced_errors}
              name={"forced_errors"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Winning Shots (WS)"}
              onChange={handleChange}
              value={winning_shots}
              name={"winning_shots"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Net Points Won (NPW)"}
              onChange={handleChange}
              value={net_points_won}
              name={"net_points_won"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Points won on Serve"}
              onChange={handleChange}
              value={points_won_on_serve}
              name={"points_won_on_serve"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Points won on Return"}
              onChange={handleChange}
              value={points_won_on_return}
              name={"points_won_on_return"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Returns (R)"}
              onChange={handleChange}
              value={state?.return}
              name={"return"}
            />
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            // disabled={disabled}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default PickleballStats
