export const checkSportIsBasketball = (sports, id) => {
    return sports?.some(e => e?.id?.toString() === id?.toString() && e?.name?.toLowerCase() === "basketball")
}

export const gotoLink = link => {
    let url = link
    if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url
    }
    window.open(url, "_blank")
}

export const ftValues = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
]

export const inValues = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
]

export const EMOJI_ICONS = [
    { key: "smile", value: '😃' },
    { key: "thumbs-up", value: '👍' },
    { key: "thumbs-down", value: '👎' },
    { key: "high-10", value: '🙌' },
    { key: "hands", value: '🙏' },
    { key: "heart", value: '❤️' },
]


export const checkURL = (name, id) => {
    switch (name) {
        case "Gymnastics":
            return `api/v1/gymnastics-player-stat-entry/?player=${id}`
        case "Golf":
            return `api/v1/golf-player-stat-entry/?player=${id}`
        case "Pickleball":
            return `api/v1/pickleball-player-stat-entry/?player=${id}`
        case "Soccer":
            return `api/v1/soccer-player-stat-entry/?player=${id}`
        case "Baseball":
            return `api/v1/baseball-player-stat-entry/?player=${id}`
        case "Volleyball":
            return `api/v1/volleyball-player-stat-entry/?player=${id}&limit=1000`
        case "Football":
            return `api/v1/football-player-stat-entry/?player=${id}&limit=1000`
        default:
            return `api/v1/player-stat-entry/?player=${id}&limit=1000`
    }
}

export const basemans = ['First Baseman', 'Second Baseman', 'Third Baseman', 'Shortstop']
export const outfielders = ['Left Fielder', 'Right Fielder', 'Center Fielder']

export const statsSummarySoccer = [
    { key: "GP", value: "matches_played", isCommon: true },
    { key: "G", value: "total_goals", isCommon: true },
    { key: "A", value: "total_assists", isCommon: true },
    { key: "SH", value: "total_shots", isCommon: true },
    { key: "SOT", value: "total_shots_on_target", isCommon: true },
    { key: "P", value: "total_passes", isCommon: true },
    { key: "PA%", value: "pass_completion_percentage", isPercentage: true, isCommon: true },
    { key: "YC", value: "total_yellow_cards", isCommon: true },
    { key: "RC", value: "total_red_cards", isCommon: true },
    { key: "KP", value: "total_key_passes", isCommon: true },
    { key: "T", value: "total_tackles", isCommon: false, type: "Midfielder" },
    { key: "SV", value: "total_saves", isCommon: false, type: "Goalkeeper" },
    { key: "CS", value: "total_clean_sheets", isCommon: false, type: "Goalkeeper" },
]

export const statsSummaryPickleball = [
    { key: "GP", value: "matches_played", isCommon: true },
    { key: "S", value: "total_serves", isCommon: true },
    { key: "A", value: "total_aces", isCommon: true },
    { key: "DF", value: "total_double_faults", isCommon: true },
    { key: "WPS", value: "winning_percentage_on_serve", isCommon: true },
    { key: "WPR", value: "winning_percentage_on_return", isCommon: true },
    { key: "UE", value: "total_unforced_errors", isPercentage: true, isCommon: true },
    { key: "FE", value: "total_forced_errors", isPercentage: true, isCommon: true },
    { key: "WS", value: "total_winning_shots", isCommon: true },
    { key: "NPW", value: "total_net_points_won", isCommon: true },
    { key: "PWS", value: "total_points_won_on_serves", isCommon: true },
    { key: "PWR", value: "total_points_won_on_returns", isCommon: true },
    { key: "R", value: "total_returns", isCommon: true },
]
export const statsSummaryGymnastics = [
    { key: "GP", value: "matches_played", isCommon: true },
    { key: "E-Score", value: "total_execution_score", isCommon: true },
    { key: "D-Score", value: "total_difficulty_score", isCommon: true },
    { key: "A-Score", value: "total_artistry_score", isCommon: true },
    { key: "Total Score", value: "total_score", isCommon: true },
    { key: "VD", value: "total_vault_difficulty", isCommon: false, type: "Vault" },
    { key: "EG-Score", value: "total_element_group_score", isCommon: false, type: "Uneven Bars" },
    { key: "CV", value: "total_connection_value", isCommon: false, type: "Balance Beam" },
    { key: "DE", value: "total_dance_elements", isCommon: false, type: "Floor Exercise" },
]
export const statsSummaryGolf = [
    { key: "GP", value: "matches_played", isCommon: true },
    { key: "TR", value: "total_rounds", isCommon: true },
    { key: "AS", value: "total_average_score", isCommon: true },
    { key: "TB", value: "total_birdies", isCommon: true },
    { key: "DA", value: "total_driving_accuracy", isCommon: true },
    { key: "ADD", value: "total_driving_accuracy", isCommon: true },
    { key: "FH", value: "total_fairways_hit", isPercentage: true, isCommon: true },
    { key: "PPR", value: "total_putts_per_round", isPercentage: true, isCommon: true },
]
export const statsSummaryBaseball = [
    { key: "GP", value: "total_games", isCommon: true },
    { key: "AB", value: "total_at_bats", isCommon: true },
    { key: "H", value: "total_hits", isCommon: true },
    { key: "HR", value: "total_home_runs", isCommon: true },
    { key: "RBI", value: "total_runs_batted_in", isCommon: true },
    { key: "AVG", value: "batting_average", isCommon: true },
    { key: "OBP", value: "total_on_base_percentage", isPercentage: true, isCommon: true },
    { key: "SLG", value: "slugging_percentage", isPercentage: true, isCommon: true },
    { key: "OPS", value: "on_base_plus_slugging", isCommon: true },
    { key: "ERA", value: "earned_run_average", isCommon: false, type: 'Pitcher' },
    { key: "SO", value: "total_strikeouts", isCommon: false, type: 'Pitcher' },
    { key: "BB", value: "total_walks", isCommon: true },
    { key: "W", value: "total_wins", isCommon: false, type: "Pitcher" },
    { key: "L", value: "total_losses", isCommon: false, type: 'Pitcher' },
    { key: "FP", value: "fielding_percentage", isPercentage: true, isCommon: false, type: "Fielder" },
]

export const statsSummaryBasketball = [
    { key: "GP", value: "games_played", isCommon: true },
    { key: "PPG", value: "points_per_game", isCommon: true },
    { key: "RPG", value: "rebounds_per_game", isCommon: true },
    { key: "APG", value: "assists_per_game", isCommon: true },
    { key: "FG%", value: "field_goal_percentage", isCommon: true },
    { key: "3P FG%", value: "three_point_field_goal_percentage", isCommon: true },
    { key: "FT%", value: "free_throw_percentage", isCommon: true },
    { key: "SPG", value: "steals_per_game", isCommon: true },
    { key: "BPG", value: "blocks_per_game", isCommon: true },
    { key: "Fouls", value: "fouls_per_game", isCommon: true },
    { key: "Turnover", value: "turnovers_per_game", isCommon: true },
    { key: "EFF", value: "eff", isCommon: true }
]

export const statsSummaryVolleyball = [
    { key: "GP", value: "matches_played", isCommon: true },
    { key: "Attacks", value: "total_attempts", isCommon: true },
    { key: "Kills", value: "kills", isCommon: true },
    { key: "Attack Errors", value: "attack_errors", isCommon: true },
    { key: "Attack %", value: "attack_percentage", isPercentage: true, isCommon: true },
    { key: "Assists", value: "assists", type: "Setter", isCommon: false },
    { key: "Assist %", value: "assist_percentage", isPercentage: true, type: "Setter", isCommon: false },
    { key: "Digs", value: "digs", isCommon: true },
    { key: "Blocks", value: "total_blocks", isCommon: true },
    { key: "Service Aces", value: "service_aces", isCommon: true },
    { key: "Service Errors", value: "service_errors", isCommon: true },
    { key: "Passing Rating", value: "passing_rating", isCommon: true },
    { key: "Reception Attempts", value: "reception_attempts", type: "Defensive Specialist", isCommon: false },
    { key: "Reception Error", value: "reception_errors", type: "Defensive Specialist", isCommon: false },
    { key: "Reception %", value: "reception_percentage", isPercentage: true, type: "Defensive Specialist", isCommon: false },
    { key: "Attack Efficiency", value: "attack_efficiency", isCommon: true },
    { key: "Passing Efficiency", value: "passing_efficiency", isCommon: true },
]


export const statsSummaryFootball = [
    { key: "GP", value: "games_played", isCommon: true },
    { key: "Touchdowns", value: "total_touchdowns", isCommon: true },
    { key: "Catch %", value: "catch_percentage", isPercentage: true, isCommon: true },
    { key: "Field Goals Attempted", value: "total_field_goals_attempted", isCommon: true },
    { key: "Field Goal %", value: "field_goal_percentage", isPercentage: true, isCommon: true },
    { key: "Yards", value: "total_yards", isCommon: true },
    { key: "Passing Completions", value: "total_passing_completions", isCommon: true },
    { key: "Passing Touchdowns", value: "total_passing_touchdowns", isCommon: true },
    { key: "QB Interceptions", value: "total_qb_interceptions", isCommon: true },
    { key: "Rushing Yards", value: "total_rushing_yards", isCommon: true },
    { key: "Rushing Attempts", value: "total_rushing_attempts", isCommon: true },
    { key: "Rushing Touchdowns", value: "total_rushing_touchdowns", isCommon: true },
    { key: "Targets", value: "total_targets", isCommon: true },
    { key: "Receptions", value: "total_receptions", isCommon: true },
    { key: "Receiving Yards", value: "total_receiving_yards", isCommon: true },
    { key: "Yards Per Carry", value: "total_yards_per_carry", isCommon: true },
    { key: "Tackles", value: "total_tackles", isCommon: true },
    { key: "Passing Yards", value: "total_passing_yards", isCommon: true },
    { key: "Field Goals Made", value: "total_field_goals_made", isCommon: true },
    { key: "Fumble Recoveries", value: "total_fumble_recoveries", isCommon: true },
    { key: "Interceptions", value: "total_interceptions", isCommon: true },
    { key: "Sacks", value: "total_sacks", isCommon: true },
    { key: "Forced Fumbles", value: "total_forced_fumbles", isCommon: true },
]

export const golfStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Goals", value: "goals" },
    { key: "Assists", value: "assists" },
    { key: "Shots", value: "shots" },
    { key: "Shots On Target", value: "shots_on_target" },
    { key: "Passes", value: "passes" },
    { key: "Passes Completed", value: "passes_completed" },
    { key: "Fouls Commited", value: "fouls_committed" },
    { key: "Fouls Suffered", value: "fouls_suffered" },
    { key: "Yellow Cards", value: "yellow_cards" },
    { key: "Red Cards", value: "red_cards" },
    { key: "Dribbles", value: "dribbles" },
    { key: "Successful Dribbles", value: "successful_dribbles" },
    { key: "Key Passes", value: "key_passes" },
    { key: "Interceptions", value: "interceptions" },
    { key: "Tackles", value: "tackles" },
    { key: "Successful Tackles", value: "successful_tackles" },
    { key: "Aerial Duels", value: "aerial_duels" },
    { key: "Aerial Duels Won", value: "aerial_duels_won" },
    { key: "Saves", value: "saves" },
    { key: "Goals Against", value: "goals_against" },
    { key: "Clean Sheets", value: "clean_sheets" },
    { key: "Penalties Faced", value: "penalties_faced" },
    { key: "Penalties Saved", value: "penalties_saved" },
    { key: "Actions", value: "Soccer" },
]

export const gymnasticsStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Execution Score (E-Score)", value: "execution_score" },
    { key: "Difficulty Score (D-Score)", value: "difficulty_score" },
    { key: "Artistry Score (A-Score)", value: "artistry_score" },
    { key: "Vault Difficulty (VD)", value: "vault_difficulty" },
    { key: "Element Group (EG) Score", value: "element_group_score" },
    { key: "Connection Value (CV)", value: "connection_value" },
    { key: "Dance Elements (DE)", value: "dance_elements" },
    { key: "Actions", value: "Gymnastics" },
]

export const pickleballStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Serves (S)", value: "serves" },
    { key: "Aces (A)", value: "aces" },
    { key: "Double Faults (DF)", value: "double_faults" },
    { key: "Unforced Errors (UE)", value: "unforced_errors" },
    { key: "Forced Errors (FE)", value: "forced_errors" },
    { key: "Winning Shots (WS)", value: "winning_shots" },
    { key: "Net Points Won (NPW)", value: "net_points_won" },
    { key: "Points won on Serve", value: "points_won_on_serve" },
    { key: "Points won on Return", value: "points_won_on_return" },
    { key: "Returns (R)", value: "return" },
    { key: "Actions", value: "Pickleball" },
]

export const soccerStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Goals", value: "goals" },
    { key: "Assists", value: "assists" },
    { key: "Shots", value: "shots" },
    { key: "Shots On Target", value: "shots_on_target" },
    { key: "Passes", value: "passes" },
    { key: "Passes Completed", value: "passes_completed" },
    { key: "Fouls Commited", value: "fouls_committed" },
    { key: "Fouls Suffered", value: "fouls_suffered" },
    { key: "Yellow Cards", value: "yellow_cards" },
    { key: "Red Cards", value: "red_cards" },
    { key: "Dribbles", value: "dribbles" },
    { key: "Successful Dribbles", value: "successful_dribbles" },
    { key: "Key Passes", value: "key_passes" },
    { key: "Interceptions", value: "interceptions" },
    { key: "Tackles", value: "tackles" },
    { key: "Successful Tackles", value: "successful_tackles" },
    { key: "Aerial Duels", value: "aerial_duels" },
    { key: "Aerial Duels Won", value: "aerial_duels_won" },
    { key: "Saves", value: "saves" },
    { key: "Goals Against", value: "goals_against" },
    { key: "Clean Sheets", value: "clean_sheets" },
    { key: "Penalties Faced", value: "penalties_faced" },
    { key: "Penalties Saved", value: "penalties_saved" },
    { key: "Actions", value: "Soccer" },
]

export const baseballStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Hits", value: "hits" },
    { key: "At Bats", value: "at_bats" },
    { key: "Home Runs", value: "home_runs" },
    { key: "Runs Batted In", value: "runs_batted_in" },
    { key: "On Base Percentage", value: "on_base_percentage", isPercentage: true },
    { key: "Walks", value: "walks" },
    { key: "Hit By Pitch", value: "hit_by_pitch" },
    { key: "Sacrifice Flies", value: "sacrifice_flies" },
    { key: "Bases", value: "bases" },
    { key: "Innings Pitched", value: "innings_pitched" },
    { key: "Earned Runs", value: "earned_runs" },
    { key: "Strikeouts", value: "strikeouts" },
    { key: "Wins", value: "wins" },
    { key: "Losses", value: "losses" },
    { key: "Saves", value: "saves" },
    { key: "Caught Stealing", value: "caught_stealing" },
    { key: "Stolen Bases Against", value: "stolen_bases_against" },
    { key: "Passed Balls", value: "passed_balls" },
    { key: "Putouts", value: "putouts" },
    { key: "Assists", value: "assists" },
    { key: "Errors", value: "errors" },
    { key: "Double Plays", value: "double_plays" },
    { key: "Outfield Assists", value: "outfield_assists" },
    { key: "Actions", value: "Baseball" },
]


export const basketballStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Points", value: "points_scored" },
    { key: "Assists", value: "assists" },
    { key: "Rebounds", value: "rebounds" },
    { key: "Steals", value: "steals" },
    { key: "Blocks", value: "blocks" },
    { key: "FGM", value: "field_goals_made" },
    { key: "FGA", value: "field_goals_attempted" },
    { key: "3P FGM", value: "three_point_field_goals_made" },
    { key: "3P FGA", value: "three_point_field_goals_attempted" },
    { key: "FTM", value: "free_throws_made" },
    { key: "FTA", value: "free_throws_attempted" },
    { key: "Turnovers", value: "turnovers" },
    { key: "Fouls", value: "fouls" },
    { key: "Actions", value: "Basketball" },
]


export const volleyballStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Attacks", value: "total_attempts" },
    { key: "Kills", value: "kills" },
    { key: "Attack Errors", value: "attack_errors" },
    { key: "Assists", value: "assists" },
    { key: "Digs", value: "digs" },
    { key: "Blocks", value: "blocks" },
    { key: "Service Aces", value: "service_aces" },
    { key: "Service Errors", value: "service_errors" },
    { key: "Passing Rating", value: "passing_rating" },
    { key: "Set Attempts", value: "set_attempts" },
    { key: "Set Errors", value: "set_errors" },
    { key: "Reception Errors", value: "reception_errors" },
    { key: "Block Assists", value: "block_assists" },
    { key: "Solo Blocks", value: "block_solos" },
    { key: "Reception Attempts", value: "reception_attempts" },
    { key: "Passing Efficiency", value: "passing_efficiency" },
    { key: "Actions", value: "Volleyball" },
]

export const footballStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Touchdowns", value: "touchdowns" },
    { key: "Field Goals Attempted", value: "field_goals_attempted" },
    { key: "Yards", value: "yards" },
    { key: "Passing Completions", value: "passing_completions" },
    { key: "Passing Touchdowns", value: "passing_touchdowns" },
    { key: "QB Interceptions", value: "qb_interceptions" },
    { key: "Rushing Yards", value: "rushing_yards" },
    { key: "Rushing Attempts", value: "rushing_attempts" },
    { key: "Rushing Touchdowns", value: "rushing_touchdowns" },
    { key: "Targets", value: "targets" },
    { key: "Receptions", value: "receptions" },
    { key: "Receiving Yards", value: "receiving_yards" },
    { key: "Yards Per Carry", value: "yards_per_carry" },
    { key: "Tackles", value: "tackles" },
    { key: "Passing Yards", value: "passing_yards" },
    { key: "Field Goals Made", value: "field_goals_made" },
    { key: "Fumble Recoveries", value: "fumble_recoveries" },
    { key: "Interceptions", value: "interceptions" },
    { key: "Sacks", value: "sacks" },
    { key: "Forced Fumbles", value: "forced_fumbles" },
    { key: "Actions", value: "football" },
]